const { TYPES } = require('./constants'); // SELECT_FIELD_MODE
//

const arr = [
  { id: 'seq_id', type: TYPES.SYSTEM },
  {
    id: 'id',
    label: 'ID Term',
    type: TYPES.INPUT,
    tab_size: 100,
    read_only: true,
  },

  {
    id: 'content',
    label: 'Content',
    type: TYPES.TEXT_AREA,
    tab_size: 500,

  },
  {
    id: 'comments',
    label: 'Comments',
    type: TYPES.TEXT_AREA,
    tab_size: 200,
  },
  {
    id: 'last_update',
    label: 'Last Update',
    type: TYPES.DATE_PICKER,
    show_time: true,
    format: 'YYYY/MM/DD HH:mm',
  },
  {
    id: 'site',
    label: 'Site',
    type: TYPES.INPUT,
  },
];
const ret = {};
let s = 1;
arr.forEach((e) => {
  /* eslint-disable no-alert, no-console */
  const ex_pattern = '^[a-z][a-z0-9_]+$';
  if (!RegExp(ex_pattern).test(e.id)) { alert(`Invalid field ${e.id}`); }
  e.tab_label = e.tab_label || e.label;
  e.seq_id = s;
  s += 1;
  ret[e.id] = e;
  /* eslint-enable no-alert, no-console */
});
ret.array = arr;
module.exports.fields = ret;
module.exports.filed_types = TYPES;
