<template>
  <div class="modal fade " ref="modalConfirmation" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog" :style="local_config.dialog_style">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ local_config.title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-if="showInternalForm"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group" v-for="(field, index) in local_config.form_fields" :key="index"
              v-if="showInternalForm">
              <label :for="field.id" style="font-weight: bold;">{{ field.label }}</label>
              <input v-if="field.type === 'label'" type="text" :disabled="true" :readonly="true" :id="field.id"
                :placeholder="field.placeholder" class="form-control" v-model="field.value" />
              <input v-if="field.type === 'text' || field.type === 'password'" :type="field.type" :id="field.id"
                :placeholder="field.placeholder" class="form-control" v-model="field.value" />
              <select v-else-if="field.type === 'select'" :id="field.id" class="form-control" v-model="field.value">
                <option v-for="(option, index) in field.select_options" :key="index" :value="option.value">{{ option.label
                }}</option>
              </select>
            </div>
            <div v-if="extraMessage?.length > 0" v-html="extraMessage">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-for="b in local_config.buttons" type="button" :key="b.label"
            :data-cy="b.cy || 'btn_' + b.label"
            :class="'btn ' + b.class" :style="b.style||''"
            :disabled="b.disabled"
            @click.prevent="onAction(b.action)">{{ b.label }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import mixinFormController from '@/mixins/form_controller';
import mixinLayoutComponents from '@/mixins/layout_components';
// import _ from 'lodash';
import { Modal } from 'bootstrap';

export default {
  name: 'user-form-modal',
  data() {
    return {
      api_name: 'user-form',
      name: 'user-form-modal',
      modalElem: null,
      show_internal_form: true,
      extra_message: '',
      modal_stated: false,
      local_config: {
        dialog_class: '',
        dialog_style: '',
        title: '...',
        body: false,
        buttons: [],
        form_fields: [],
      },
    };
  },

  mixins: [mixinLayoutComponents],
  components: {

  },
  computed: {
    extraMessage: {
      get() {
        return this.extra_message;
      },
      set(v) {
        this.extra_message = v;
      },
    },
    showInternalForm: {
      get() {
        return this.show_internal_form;
      },
      set(v) {
        this.show_internal_form = v;
      },
    },
  },
  methods: {
    showModal(config) {
      this.local_config.title = config?.title || '...';
      this.local_config.body = config?.body || false;
      this.local_config.buttons = config?.buttons || [];
      this.local_config.dialog_class = config?.dialog_class || '';
      this.local_config.dialog_style = config?.dialog_style || '';
      this.local_config.form_fields = config?.form_fields || [];
      console.log('TEST DEBUG 240203 showModal: ', { local_config: this.local_config });

      this.modalElem.show();
    },
    showForm(show_form, message = '') {
      this.showInternalForm = show_form;
      this.extraMessage = message;
    },
    onAction(action) {
      // this.modalElem.hide();
      action();
    },
  },
  mounted() {
    this.modal_stated = true;
    this.modalElem = new Modal(this.$refs.modalConfirmation);
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() { },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped></style>
