<template>
  <div class="modal fade " ref="modalDictionary" id="staticBackdrop" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Dictionary </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12" style="">
              <form-field v-for="f in formFieldsSideA" :key="f.id" :read="false" :field_info="f"></form-field>
            </div>
          </div>
          <div class="row" v-if="local_data?.meta_keys?.length > 0">
            <div class="col-12" style="" v-html="loadMetaKeys()">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="">Close</button>
          <button type="button" class="btn btn-primary" @click="onSave()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinFormController from '@/mixins/form_controller';
import mixinLayoutComponents from '@/mixins/layout_components';
// import _ from 'lodash';
import { Modal } from 'bootstrap';
import { fields } from '../../../../../sender/api/rules/fields_dictionary';

export default {
  name: 'manager_dictionary-form',
  data() {
    return {
      local_fields_ref_a: false,
      local_fields_ref_b: false,
      api_name: 'manager_dictionary',
      name: 'manager_dictionary-form',
      modalElem: null,
      local_id: null,
      onClose: null,
      local_data: {},
    };
  },

  mixins: [mixinLayoutComponents, mixinFormController],
  components: {},
  computed: {
    localId: {
      get() {
        return this.local_id;
      },
      set(v) {
        this.local_id = v;
      },
    },
    seqId: {
      get() {
        return this.local_id;
      },
      set(v) {
        this.local_id = v;
      },
    },
    formFieldsSideA() {
      if (this.local_fields_ref_a === false) {
        this.local_fields_ref_a = this.setFormDefaultFields(['id', 'content', 'comments'], fields);
      }
      return this.local_fields_ref_a;
    },
  },
  methods: {
    loadMetaKeys() {
      if (this.local_data?.meta_keys?.length > 0) {
        return `You can use this items: {{${this.local_data.meta_keys.split(',').join('}} , {{')}}}`;
      }
    },
    showModal(id) {
      console.log('TEST DEBUG 240102 (72 at dictionary-modal.vue)[00:57]: ', { id });
      this.localId = id;
      // _.find(this.formFieldsSideA, (f) => f.id === 'from_date').ref_field.setValue(new Date());
      // _.find(this.formFieldsSideA, (f) => f.id === 'customer_id').ref_field.setValue('');
      // _.find(this.formFieldsSideA, (f) => f.id === 'notes').ref_field.setValue('');
      this.loadFormData(id);
      this.modalElem.show();
    },
    loadFormData(id) {
      console.log('TEST DEBUG 240102 (81 at dictionary-modal.vue)[00:56]: ', { localID: this.localId, id });
      this.commonLoadRecord({ force_seq_id: id }, (err, result) => {
        this.setFormFields(result.data);
        this.local_data = result.data;
        console.log('Load loadFormData ', this.localId, { err, result });
      });
    },
    onSave() {
      console.log('TEST DEBUG 240102 (88 at dictionary-modal.vue)[01:06]: ', { });
      const changes = this.getFormFieldsValues(false);
      // changes.seq_id = this.localId;
      console.log('TEST DEBUG 240102 (90 at dictionary-modal.vue)[01:05]: ', { changes });
      this.commonSaveRecord(changes, { force_seq_id: this.localId }, (err, result) => {
        if (err) {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err,
          });
        } else {
          this.$notify({
            type: 'success',
            title: 'Saved',
            text: 'Success',
          });
          if (typeof this.onClose == 'function') {
            this.onClose(result);
          }
          this.modalElem.hide();
        }
      });
    },
  },
  props: ['id'],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);
    this.modalElem = new Modal(this.$refs.modalDictionary);

    // if (this.localID === 'new') {
    this.is_new = false;
    // } else {

    // }
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() { },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped></style>
