<template>
  <div class="row" style="display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0;">
    <div class="col-6">
      <div style="display: flex; justify-content: center; align-items: center;">
        <img src="/img/logo_v_x3.png" alt="e-attach" width="453" height="270" />
      </div>
      <form>
        <div style="margin-top:20px">
          <input class="form_item form-control" type="text" v-model="user_name_value" placeholder="ユーザー名" autocomplete="username" data-cy="username-input" /><br>
          <input class="form_item form-control" type="password" v-model="password_value" placeholder="パスワード" autocomplete="current-password" data-cy="password-input" /><br>
          <button class="form_item btn btn-primary" type="submit" style="" @click.prevent="onLogin" data-cy="login-button">ログイン</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user_name_value: '',
      password_value: '',
    };
  },
  routes: [{
    path: '/login', name: 'Login', component: this, meta: { requiresAuth: false },
    // path: '/expired', name: 'Login', component: this, meta: { requiresAuth: false },
  }],

  methods: {
    onLogin() {
      const body = {
        user_name: this.user_name_value,
        password: this.password_value,
      };

      this.$ajax.post('/user/login/manager/', body, (err, data) => {
        console.log({ err, data, body });

        if (err) {
          this.$notify({
            type: 'error',
            title: '予期しないエラー',
            text: err,
          });
        }
        if (data.user_is_blocked === true) {
          this.$notify({
            type: 'error',
            title: 'Access blocked',
            text: 'Your user account is blocked, contact your administrator',
          });
          return;
        }
        if (data.tenant_is_blocked === true) {
          this.$notify({
            type: 'error',
            title: 'Access blocked',
            text: 'Your account is blocked.',
          });
          return;
        }
        if (data.logged === true) {
          if (data.user_level !== 9) {
            this.$notify({
              type: 'error',
              title: 'Login',
              text: `Invalid Access Level ${data.user_level}`,
            });
            return;
          }
          localStorage.setItem('tenant_id', data.tenant_id);
          localStorage.setItem('display_name', data.display_name);
          localStorage.setItem('user_name', data.user_name);
          localStorage.setItem('user_level', data.user_level);
          this.$router.push('/');
          this.$notify({
            type: 'success',
            title: 'Login',
            text: 'Success',
          });
        } else {
          this.$notify({
            type: 'error',
            title: 'Login',
            text: 'Invalid username or password',
          });
        }
      });
    },
  },

  components: {},
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
.btn-primary {
    background-color: #6b71f2; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}
.form_item{
  display: block;
  margin: 0 auto;
  width:300px;
}

</style>
