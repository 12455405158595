<template>
  <MainLayout menu_id="tenant_form">
    <ManagerContainer v-if="isLoaded === false">
      Loading...
    </ManagerContainer>
    <ManagerContainer v-if="isLoaded">
        Account: {{ tenantData.tenant_name }}
        <ul class="nav nav-tabs" style="margin-top:0px;margin-bottom:0px;" role="tablist">
          <li class="nav-item" role="presentation">
            <a :class="(pageMode === 'account')? 'nav-link active':'nav-link inactive_tab'"   :href="'/accounts/account/' + tenantId"
              data-cy="account-info-link"
            >Account Info</a>
          </li>
          <li class="nav-item" role="presentation" v-if="tenantId != 'new'">
            <a :class="(pageMode === 'users')? 'nav-link active':'nav-link inactive_tab'"   :href="'/accounts/users/' + tenantId"
              data-cy="users-link"
            >Users</a>
          </li>
          <li class="nav-item" role="presentation" v-if="tenantId != 'new'">
            <a :class="(pageMode === 'settings')? 'nav-link active':'nav-link inactive_tab'"   :href="'/accounts/settings/' + tenantId"
              data-cy="settings-link"
            >Settings</a>
          </li>
        </ul>
        <div class="row" v-if="pageMode === 'account'">
          <form>
          <div class="col-4" v-if="tenantData !== false">
            <div class="form-group">
              <label style="font-weight: bold;" for="tenantId">Id</label>
              <input type="text" class="form-control" id="tenantId" v-model="tenantData.id" readonly
                v-if="tenantId != 'new'" data-cy="tenant-id-input">
            </div>
          </div>
          <div class="col-4" v-if="tenantData !== false">
            <div class="form-group">
              <label style="font-weight: bold;" for="tenantName">Name</label>
              <input type="text" class="form-control" id="tenantName" v-model="tenantData.tenant_name" data-cy="tenant-name-input">
            </div>
          </div>
          <div class="col-4" v-if="tenantData !== false">
            <div class="form-group">
              <label style="font-weight: bold;" for="tenantCName">CNAME (Subdomain)</label>
              <input type="text" class="form-control" id="tenantCName" v-model="tenantData.cname" data-cy="tenant-name-input">
            </div>
          </div>
          <div class="col-4" v-if="tenantData !== false">
            <div class="form-group">
              <label style="font-weight: bold;" for="tenantStatus">Status</label>
              <select class="form-select" id="tenantStatus" v-model="tenantData.tenant_status" data-cy="tenant-status-select">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div class="col-12" v-if="tenantData !== false">
            <div class="form-group">
              <label style="font-weight: bold;" for="tenantDescription">Description</label>
              <textarea class="form-control" id="tenantDescription" rows="3"
                v-model="tenantData.tenant_description" data-cy="tenant-description-textarea"></textarea>
            </div>
            <button class="btn btn-danger" style="float:left; margin-top:10px;"
              v-if="countUsers === 0 && tenantData.tenant_status === 0" @click.prevent="deleteTenant" data-cy="delete-tenant-button">Delete</button>
            <button type="submit" class="btn btn-primary" style="float:right; margin-top:10px;"
              @click.prevent="saveTenant" data-cy="save-tenant-button">Save</button>
          </div>
        </form>
      </div>
      <div class="row"  v-if="pageMode === 'users' && tenantId != 'new'">
        <div class="col-12">
          <AgTable ref="userTableRef" :useAddNew="true" :useSearch="true" :allFields="userFieldList"
            :tabFields="userTabFieldList" apiName="manager_user" :apiRequestOptions="getUserApiRequestOptions()"
            @onRowClicked="onUserRowClicked" @onAddNew="onNewUser"></AgTable>
        </div>
      </div>
      <div class="row"  v-if="pageMode === 'settings' && tenantId != 'new'">
        <div class="col-12">
          <AgTable ref="settingsTableRef" :useAddNew="false" :useSearch="false" :allFields="settingsFieldList"
            :tabFields="settingsTabFieldList" apiName="manager_settings"
            :apiRequestOptions="getSettingsApiRequestOptions()" @onRowClicked="onSettingsRowClicked"></AgTable>
        </div>
      </div>
    </ManagerContainer>
    <UserFormModal ref="formModalRef"></UserFormModal>
    <ConfirmationModal ref="confirmationModalRef"></ConfirmationModal>
  </MainLayout>
</template>
<script>
// import moment from 'moment';
// import PageHeader from '@/components/header.vue';
import ManagerContainer from '@/components/manager-container.vue';
import MainLayout from '@/layouts/mainLayout.vue';
import AgTable from '@/components/ag-table.vue';

import UserFormModal from '@/components/modals/generic-form-modal.vue';
import ConfirmationModal from '@/components/modals/confirmation-modal.vue';

import _ from 'lodash';
// import { ja } from 'date-fns';
import { fields as user_fields } from '../../../../sender/api/rules/fields_user';
import { fields as settings_fields } from '../../../../sender/api/rules/fields_settings';

export default {
  data() {
    return {
      name: 'tenant_form',
      api_name: 'manager_tenant',
      user_modal_elem: null,
      // detail_page: 'attach-form',
      tenant_data: false,
      is_loaded: false,
      selected_id: null,
      tenant_id: null,
      count_users: 0,
    };
  },
  routes: [{
    // path: '/menu', name: 'Menu', component: this, meta: { requiresAuth: true },
    path: '/accounts/:mode/:id', name: 'TenantForm', component: this, meta: { requiresAuth: true },
  }],
  computed: {
    pageMode() {
      return this.$route.params.mode;
    },
    countUsers: {
      get() {
        return this.count_users;
      },
      set(v) {
        this.count_users = v;
      },
    },
    tenantId: {
      get() {
        return this.tenant_id;
      },
      set(v) {
        this.tenant_id = v;
      },
    },
    selectedId: {
      get() {
        return this.selected_id;
      },
      set(v) {
        this.selected_id = v;
      },
    },
    userFieldList() {
      return user_fields;
    },
    settingsFieldList() {
      return settings_fields;
    },
    settingsTabFieldList() {
      const list = [
        _.extend(settings_fields.key_id, {}),
        _.extend(settings_fields.key_value, {
          forceCellRenderer: (params) => {
            switch (params.value) {
              case 'yes':
                return 'Yes';
              case 'no':
                return 'No';
              case 'on_draft':
                return 'Only at Draft page';
              case '10':
                return '10 minutes';
              case '1':
                return '1 minute';
              case '20':
                return '20 minutes';
              case '30':
                return '30 minutes';
              case '60':
                return '1 hour';
              case '180':
                return '3 hours';
              case '480':
                return '8 hours';
              case '1440':
                return '1 day';
              case '4320':
                return '3 days';
              default:
                return params.value;
            }
          },
        }),
        _.extend(settings_fields.user_id, {}),
      ];
      return list;
    },
    userTabFieldList() {
      const list = [
        _.extend(user_fields.user_name, {}),
        _.extend(user_fields.display_name, {}),
        _.extend(user_fields.user_status, {
          forceCellRenderer: (params) => ((parseInt(params.value) === 1) ? 'Active' : 'Inactive'),
        }),
        _.extend(user_fields.user_level, {
          forceCellRenderer: (params) => {
            switch (params.value) {
              case 1:
                return 'User';
              case 5:
                return 'Account Manager';
              case 9:
                return 'Master Admin';
              default:
                return 'Unknown';
            }
            // return (params.value === '1') ? 'Active' : 'Inactive';
          },
        }),
      ];
      return list;
    },
    searchFieldList() {
      return [
        _.extend(user_fields.to_mail, {}),
      ];
    },
    displayName() {
      console.log('TEST DEBUG 231015 (29 at menu.vue)[18:11]: Load displayName', {});
      return localStorage.getItem('display_name');
    },
    isLoaded: {
      get() {
        return this.is_loaded;
      },
      set(v) {
        this.is_loaded = v;
      },
    },
    tenantData: {
      get() {
        return this.tenant_data;
      },
      set(v) {
        this.tenant_data = v;
      },
    },
  },
  mixins: [],
  components: {
    MainLayout, ManagerContainer, AgTable, UserFormModal, ConfirmationModal,
  },
  methods: {
    deleteTenant() {
      this.confirmation_modal_config = {
        title: 'Are you sure you want to delete this account?',
        body: 'This action cannot be undone.<br> There are no other confirmation dialogs after this one.',
        buttons: [
          {
            label: 'Yes, delete the account',
            cy: 'delete-tenant-confirmation-button',
            class: 'btn-danger',
            action: () => {
              const api_url = `/${this.api_name}/delete/${this.tenantId}`;
              const body = { tenant_id: this.tenantId };
              this.$ajax.post(api_url, body, (err, _result) => {
                if (err) {
                  this.$notify({ type: 'error', title: '予期しないエラー', text: err });
                } else {
                  this.$notify(
                    { type: 'success', title: 'Deleted', text: 'Success' },
                  );
                  this.$router.push('/accounts/');
                }
              });
            },
          },
          {
            label: 'No, cancel',
            class: 'btn-secondary',
            action: () => {
            },
          },
        ],
      };
      this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
    },
    saveTenant() {
      const saveData = _.cloneDeep(this.tenantData);
      let tenant_id = saveData.id;
      if (saveData.id === 'new') {
        tenant_id = Number.MAX_SAFE_INTEGER;
        saveData.id = tenant_id;
      }
      const api_url = `/${this.api_name}/set/${tenant_id}`;
      const body = { changes: saveData };
      this.$ajax.post(api_url, body, (err, result) => {
        console.log({ err, result, body });
        if (err) {
          this.$notify({ type: 'error', title: '予期しないエラー', text: err });
        } else {
          if (this.tenantData.id === 'new') {
            this.tenantId = result.data.id;
            this.$router.push(`/accounts/account/${result.data.id}`);
            this.tenantId = result.data.id;
          } else {
            this.tenantData = result.data;
          }
          this.$notify(
            { type: 'success', title: 'Saved', text: 'Success' },
          );
        }
      });
    },
    createAPassword(length) {
      let result = '';
      const characters = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz23456789'; // -*(){}[]<>!^_~';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    onNewUser() {
      const form_fields = [
        {
          id: 'user_name', label: 'User Name', type: 'text', value: '',
        },
        {
          id: 'user_password', label: ' Password', type: 'text', value: this.createAPassword(12),
        },
        {
          id: 'display_name', label: 'Display Name', type: 'text', value: '',
        },
        {
          id: 'email', label: 'E-mail', type: 'text', value: '',
        },
        {
          id: 'user_status',
          label: 'Status',
          type: 'select',
          value: '1',
          select_options: [
            { value: '1', label: 'Active' },
            { value: '0', label: 'Inactive' },
          ],
        },
        {
          id: 'user_level',
          label: 'Level',
          type: 'select',
          value: '1',
          select_options: [
            { value: '1', label: 'User' },
            { value: '5', label: 'Account Manager' },
          ],
        },
      ];

      const buttons = [
        {
          label: 'Save',
          cy: 'save-user-button',
          class: 'btn-primary',
          action: () => {
            buttons[0].disabled = true;
            buttons[1].disabled = true;
            this.$refs.formModalRef.showForm(false, 'Processing...');
            const obj = { tenant_id: this.tenantId };
            form_fields.forEach((f) => {
              obj[f.id] = f.value;
            });
            this.$ajax.post('/manager_user/create_user', { changes: obj }, (err, _result) => {
              buttons[1].disabled = false;
              buttons[0].disabled = false;

              if (err) {
                this.$notify({
                  type: 'error',
                  title: '予期しないエラー',
                  text: err,
                });
                this.$refs.formModalRef.showForm(true, err || 'Error');
              } else {
                this.$notify({
                  type: 'success',
                  title: 'Saved',
                  text: 'Success',
                });
                this.$refs.formModalRef.modalElem.hide();
                this.$refs.userTableRef.reloadData();
                this.countUsers += 1;
              }
            });
          },
        },
        { label: 'Cancel', class: 'btn-secondary', action: () => { this.$refs.formModalRef.modalElem.hide(); } },
      ];

      this.$refs.formModalRef.showForm(true, '');
      this.$refs.formModalRef.showModal({
        title: 'Add new user',
        body: false,
        form_fields,
        buttons,

      });
    },
    getUserApiRequestOptions() { return { sub_search: { tenant_id: this.$route.params.id } }; },
    getSettingsApiRequestOptions() { return { sub_search: { tenant_id: this.$route.params.id } }; },

    onDeleteUserConfirmationModal(id, name) {
      this.confirmation_modal_config = {
        title: 'Are you sure you want to delete this user?',
        body: `The user and all this attachments will be deleted. This action cannot be undone. <br><br>User name: ${name}<br>ID: ${id}`,
        buttons: [
          {
            label: 'Yes, delete',
            cy: 'delete-user-confirmation-button',
            class: 'btn-danger',
            action: () => {
              this.onDeleteUserConfirmationModalPhase2(id, name);
            },
          },
          {
            label: 'No, cancel',
            class: 'btn-secondary',
            action: () => {
            },
          },
        ],
      };
      this.$refs.confirmationModalRef.showModal(this.confirmation_modal_config);
    },
    onDeleteUserConfirmationModalPhase2(id, name) {
      const form_fields = [
        {
          id: 'id', label: 'User ID', type: 'label', value: id,
        },
        {
          id: 'name', label: 'User name', type: 'label', value: name,
        },
        {
          id: 'confirm_user_name', label: "Type the 'User name' to confirm you want to delete", type: 'text', value: '',
        },
      ];
      const buttons = [
        {
          label: 'Delete',
          cy: 'delete-user-confirmation-button-phase2',
          class: 'btn-danger',
          action: (_data) => {
            this.$refs.formModalRef.showForm(false, 'Processing...');
            const typed_user_name = form_fields[2].value;
            if (typed_user_name === name) {
              this.$ajax.post(`/manager_user/delete/${id}`, {}, (err, _result) => {
                if (err) {
                  this.$notify({
                    type: 'error',
                    title: '予期しないエラー',
                    text: err,
                  });
                  this.$refs.formModalRef.showForm(true, err || 'Error');
                } else {
                  this.$notify({
                    type: 'success',
                    title: 'Deleted',
                    text: 'Success',
                  });
                  this.$refs.formModalRef.modalElem.hide();
                  this.$refs.userTableRef.reloadData();
                }
              });
            } else {
              this.$notify({
                type: 'error',
                title: '予期しないエラー',
                text: "The 'User name' typed is not the same as the user name to be deleted",
              });
              this.$refs.formModalRef.showForm(true, "The 'User name' typed is not the same as the user name to be deleted");
            }
          },
        },
        { label: 'Cancel', class: 'btn-secondary', action: () => { this.$refs.formModalRef.modalElem.hide(); } },

      ];

      this.$refs.formModalRef.showForm(true, '');
      this.$refs.formModalRef.showModal({
        title: 'Last chance to cancel',
        body: false,
        form_fields,
        buttons,
        dialog_style: 'max-width: 800px;',

      });
    },
    onSettingsRowClicked(ev) {
      // When edit, should sent this tenant_id and not the logged one.
      // Should how an option to use the default value, in this case, then should delete the value from the database to use the default one.
      // The update or the delete, should be based on tenant_id, user_id and key_id

      let select_options = [];

      if (ev.data.key_id === 'attach_show_password' || ev.data.key_id === 'upload_show_password') {
        select_options = [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
          { value: 'on_draft', label: 'Only at Draft page' },
        ];
      } else if (ev.data.key_id === 'sender_user_session_timeout') {
        select_options = [
          { value: '1', label: '1 minute (only for tests)' },
          { value: '10', label: '10 minutes' },
          { value: '20', label: '20 minutes' },
          { value: '30', label: '30 minutes' },
          { value: '60', label: '1 hour' },
          { value: '180', label: '3 hours' },
          { value: '480', label: '8 hours' },
          { value: '1440', label: '1 day' },
          { value: '4320', label: '3 days' },
        ];
      } else {
        select_options = [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ];
      }

      const form_fields = [
        {
          id: 'user_id', label: 'User ID', type: 'label', value: ev.data.user_id,
        },
        {
          id: 'tenant_id', label: 'Account ID', type: 'label', value: ev.data.tenant_id,
        },
        {
          id: 'key_id', label: 'Setting Key', type: 'label', value: ev.data.key_id,
        },
        {
          id: 'key_value', label: 'Value', type: 'select', value: ev.data.key_value, select_options,
        },
      ];
      const buttons = [
        {
          label: 'Save',
          cy: 'save-settings-button',
          class: 'btn-primary',
          action: () => {
            buttons.forEach((b) => {
              b.disabled = true;
            });
            this.$refs.formModalRef.showForm(false, 'Processing...');
            const obj = { tenant_id: this.tenantId };
            form_fields.forEach((f) => {
              obj[f.id] = f.value;
            });
            const url = `/manager_settings/set/${this.$route.params.id}/${ev.data.user_id}/${ev.data.key_id}`;
            this.$ajax.post(url, { changes: obj }, (err, _result) => {
              buttons.forEach((b) => {
                b.disabled = false;
              });
              if (err) {
                this.$notify({
                  type: 'error',
                  title: '予期しないエラー',
                  text: err,
                });
                this.$refs.formModalRef.showForm(true, err || 'Error');
              } else {
                this.$notify({
                  type: 'success',
                  title: 'Saved',
                  text: 'Success',
                });
                this.$refs.formModalRef.modalElem.hide();
                this.$refs.settingsTableRef.reloadData();
              }
            });
          },
        },
        { label: 'Cancel', class: 'btn-secondary', action: () => { this.$refs.formModalRef.modalElem.hide(); } },

      ];

      this.$refs.formModalRef.showForm(true, '');
      this.$refs.formModalRef.showModal({
        title: 'Edit account setting',
        body: false,
        form_fields,
        buttons,
        dialog_style: 'max-width: 800px;',

      });
    },
    onUserRowClicked(ev, password = false) {
      console.log('TEST DEBUG 240218 (171 at accounts-form.vue)[14:27]: ', { ev });

      let user_level = {
        id: 'user_level',
        label: 'Level',
        type: 'select',
        value: ev.data.user_level,
        select_options: [
          { value: '1', label: 'User' },
          { value: '5', label: 'Account Manager' },
        ],
      };
      if (ev.data.user_level === 9) {
        user_level = {
          id: 'user_level', label: 'Level', type: 'label', value: 'Master Admin',
        };
      }

      let form_fields = [
        {
          id: 'user_name', label: 'User Name', type: 'text', value: ev.data.user_name,
        },
        {
          id: 'display_name', label: 'Display Name', type: 'text', value: ev.data.display_name,
        },
        {
          id: 'email', label: 'E-mail', type: 'text', value: ev.data.email,
        },
        {
          id: 'user_status',
          label: 'Status',
          type: 'select',
          value: ev.data.user_status,
          select_options: [
            { value: '1', label: 'Active' },
            { value: '0', label: 'Inactive' },
          ],
        },
        user_level,
      ];
      if (password === true) {
        form_fields = [
          {
            id: 'user_name', label: 'User Name', type: 'label', value: ev.data.user_name,
          },
          {
            id: 'display_name', label: 'Display Name', type: 'label', value: ev.data.display_name,
          },
          {
            id: 'user_password', label: 'New Password', type: 'text', value: '',
          }];
      }

      const buttons = [
        {
          label: 'Change password',
          class: 'btn-info',
          style: parseInt(ev.data.user_status) === 1 ? 'margin-right: auto;' : '',
          action: () => {
            this.$refs.formModalRef.modalElem.hide();
            setTimeout(() => {
              this.onUserRowClicked(ev, true);
            }, 500);
          },
        },
        {
          label: 'Delete user',
          cy: 'delete-user-button',
          class: 'btn-danger',
          style: 'margin-right: auto;',
          action: () => {
            this.$refs.formModalRef.modalElem.hide();
            // Show a confirmation dialog
            this.onDeleteUserConfirmationModal(ev.data.id, ev.data.user_name);
          },
        },
        {
          label: 'Save',
          cy: 'save-user-button',
          class: 'btn-primary',
          action: () => {
            if (!password) {
              buttons[0].disabled = true;
              buttons[1].disabled = true;
            }

            this.$refs.formModalRef.showForm(false, 'Processing...');
            const obj = { tenant_id: this.tenantId };
            form_fields.forEach((f) => {
              if (f.id === 'user_level') {
                console.log('TEST DEBUG 240712 (681 at accounts-form.vue)[02:06]: user_level', { value: f.value });
                if ((f.value === 1 || f.value === 5 || f.value === '1' || f.value === '5')) {
                  obj[f.id] = f.value;
                }
              } else {
                obj[f.id] = f.value;
              }
            });
            let url = `/manager_user/set/${ev.data.id}`;
            if (password) {
              url = `/manager_user/set_password/${ev.data.id}`;
            }
            this.$ajax.post(url, { changes: obj }, (err, _result) => {
              if (!password) {
                buttons[1].disabled = false;
                buttons[0].disabled = false;
              }

              if (err) {
                this.$notify({
                  type: 'error',
                  title: '予期しないエラー',
                  text: err,
                });
                this.$refs.formModalRef.showForm(true, err || 'Error');
              } else {
                this.$notify({
                  type: 'success',
                  title: 'Saved',
                  text: 'Success',
                });
                this.$refs.formModalRef.modalElem.hide();
                this.$refs.userTableRef.reloadData();
              }
            });
          },
        },
        { label: 'Cancel', class: 'btn-secondary', action: () => { this.$refs.formModalRef.modalElem.hide(); } },

      ];
      if (password === true) {
        buttons.splice(0, 2);
      }
      if (parseInt(ev.data.user_status) === 1) {
        buttons.splice(1, 1);
      }

      this.$refs.formModalRef.showForm(true, '');
      this.$refs.formModalRef.showModal({
        title: (password === true) ? 'Edit user password' : 'Edit user data',
        body: false,
        form_fields,
        buttons,
        dialog_style: 'max-width: 800px;',

      });
    },
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    this.tenantId = this.$route.params.id;

    if (this.$route.params.id === 'undefined') {
      this.$notify({
        type: 'error',
        title: '予期しないエラー',
        text: 'Your URL is invalid',
      });
      this.isError = true;
    } else if (this.$route.params.id === 'new') {
      console.log('TEST DEBUG 240218 (189 at accounts-form.vue)[17:08]: in the new', {});
      this.tenantData = {
        id: 'new',
        tenant_name: '',
        tenant_status: '1',
        cname: 'app',
        tenant_description: '',
      };
      this.isLoaded = true;
    } else {
      // Load the attach from the API
      console.log('TEST DEBUG 240218 (200 at accounts-form.vue)[17:08]:  NOT IN THE NEW', { ley: this.$route.params.id });
      const api_url = `/manager_tenant/get/${this.tenantId}`;
      const body = {
      };
      this.$ajax.post(api_url, body, (err, result) => {
        console.log('TEST DEBUG 240122 (359 at attach.vue)[19:40]: ', { err, result, body });
        if (err) {
          this.$notify({
            type: 'error',
            title: '予期しないエラー',
            text: err,
          });
        } else {
          // this.$router.push('/new/' + data.attach_id);
          this.tenantData = result.data;
          this.isLoaded = true;
          this.countUsers = result.data.count_users;
        }
      });
    }
  },
  mounted() {
    // this.gridOptions.api.sizeColumnsToFit();
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style >
.btn-primary {
  background-color: #6b71f2;
  /* Primary color */
  border-color: #5a62d2;
  /* Slightly darker shade for the border */
}

.btn-primary:hover {
  background-color: #6268e0;
  /* Slightly darker shade for hover */
  border-color: #4f58c2;
  /* Optionally, you could also darken the border color slightly on hover */
}

.inactive_tab {
  color: #6268e0;
}

a.attach_link {
  color: #6268e0;
  font-size: 16px;
  ;
}

.ag-header-cell-text {
  font-size: 16px !important;
  font-weight: bold !important;
}

.ag-row {
  font-size: 16px !important;
  height: 35px !important;
  color: #6268e0 !important;
}

.ag-cell {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ag-theme-bootstrap .ag-ltr .ag-cell {
  border: none !important;
}

.ag-row:hover {
  background-color: #6b71f2 !important;
  color: white !important;

}

.ag-row-selected {
  background-color: #6268e0 !important;
  color: white !important;
}

.ag-theme-bootstrap {
  border: 1px solid #ccc !important;
}

.ag-header-cell:not(:first-child) {
  border-left: 1px solid #ddd !important;
}
</style>
