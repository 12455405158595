<template>
  <div class="row">
    <div class="col-3" ></div>
    <div class="col-6">
      処理中...
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  routes: [
    {
      path: '/logout', name: 'Logout', component: this, meta: { requiresAuth: false },
    },
    {
      path: '/expired', name: 'Expired', component: this, meta: { requiresAuth: false },
    }],

  methods: {
    onLogout() {
      const body = {
      };
      localStorage.removeItem('tenant_id');
      localStorage.removeItem('display_name');
      localStorage.removeItem('user_name');

      this.$ajax.post('/user/logout', body, (err, data) => {
        console.log({ err, data, body });

        if (err) {
          this.$notify({
            type: 'error',
            title: '予期しないエラー',
            text: err,
          });
        } else {
          this.$router.push('/login');
          this.$notify({
            type: 'success',
            text: 'Logged out',
          });
        }
      });
    },
  },
  mounted() {
    console.log('TEST DEBUG 231015 (56 at logout.vue)[18:05]: LOGOUT MOUNTED ', { });
    this.onLogout();
  },

  components: {},
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
.btn-primary {
    background-color: #6b71f2; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}
</style>
